import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'
import { Link } from 'react-router-dom'
import moment from 'moment'
import Utils from '../../../Utils';

var lodash = require('lodash')

class Index extends Component {

  constructor(props) {
    super(props);

    moment.locale('fr')

    this.state = {
      affaires: [],
      offres: [],
      start_date: moment().subtract(2, 'month').startOf('month'),
      end_date: moment().endOf('month'),
      fetchInProgress: true,
      linkEsellerLead: '',
    }
  }

  componentWillMount() {
    this.fetchLinks()
    this.fetchLeads()
    this.fetchOffres()
  }

  generateTooltips() {
    window.$('[data-toggle="tooltip"]').tooltip();
  }

  fetchLinks() {
    fetch(localStorage.backoffice_api + '/api/bdref3/urls', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      var indexLinks = lodash.findIndex(json, (l) => {
        return l.URL.toLowerCase().search('e-seller.selectup.com') !== -1 || l.URL.toLowerCase().search('smit.selectup.com') !== -1;
      })

      if (indexLinks < 0) {
        return;
      }

      var linkEsellerLead = '';
      if (Utils.fromJWT('serverName') === 'PROD-CRM-SQL1' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL2' || Utils.fromJWT('serverName') === 'PROD-CRM-SQL3') {
        linkEsellerLead = 'http://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'lead.e-seller.selectup.com').replace('smit.selectup.com', 'lead.e-seller.selectup.com');
      }
      else {
        linkEsellerLead = 'http://'+json[indexLinks].URL.replace('e-seller.selectup.com', 'dev.lead.e-seller.selectup.com').replace('smit.selectup.com', 'dev.lead.e-seller.selectup.com');
      }

      console.log(linkEsellerLead)

      this.setState({
        linkEsellerLead: linkEsellerLead,
        urls: json
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchLeads() {
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/wally/lead?start_date='+this.state.start_date.format('YYYY-MM-DD HH:mm:ss')+'+&end_date='+this.state.end_date.format('YYYY-MM-DD HH:mm:ss'), {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        affaires: json,
        fetchInProgress: false,
      }, () => {
        this.generateTooltips()
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  fetchOffres() {
    this.setState({
      fetchInProgress: true,
    })

    fetch(localStorage.backoffice_api + '/api/interfaces/wally/offres?start_date='+this.state.start_date.format('YYYY-MM-DD HH:mm:ss')+'+&end_date='+this.state.end_date.format('YYYY-MM-DD HH:mm:ss'), {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
    .then((response) => {
      if (!response.ok) {
        throw Error(response.statusText);
      }
      return response.json();
    })
    .then((json) => {
      this.setState({
        offres: json,
        fetchInProgress: false,
      }, () => {
        this.generateTooltips()
      });
    })
    .catch((error) => {
      console.log(error)
    });
  }

  generateLogo(constructeur) {

    if (constructeur == 'STELLANTIS') {
      return <img src="https://companieslogo.com/img/orig/STLA-7ff80a99.png?t=1634834340" style={{ maxHeight: '30px' }}></img>
    }

    if (constructeur == 'HYUNDAI') {
      return <img src="https://companieslogo.com/img/orig/HYMTF-f0fcea48.png?t=1634834834" style={{ maxHeight: '30px' }}></img>
    }

    return <span>{constructeur}</span>

  }

  generateLine(affaire) {

    return (<tr key={affaire.CodeAffaireSource}>
        <td style={{ verticalAlign: 'middle', textAlign:'center' }}><a href={this.getUrlOngletConstructeur(affaire.CodeAffaireSource, affaire.Comment)} target='_blank'>{this.generateLogo(affaire.Constructeur)}</a></td>
        <td style={{ verticalAlign: 'middle', }}>{moment(affaire.DateDebut).utc().format('DD/MM/YYYY HH:mm')}</td>
        <td style={{ verticalAlign: 'middle', }}>{affaire.Site}</td>
        <td style={{ verticalAlign: 'middle', }}>{affaire.Origine}</td>
        <td style={{ verticalAlign: 'middle', }}>
          <a className='mr-2' href={this.state.linkEsellerLead + '/prospect/' + affaire.IDClient} target='_blank'>
            <i className="fa fa-external-link " aria-hidden="true"></i>
          </a>
          <a className='mr-2' href={this.state.linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/customers/' + affaire.IDClient} target='_blank'>
          <i className="fa fa-external-link " aria-hidden="true"></i>
        </a>
          {affaire.Client_name} {affaire.Client_phone} {affaire.Client_email}
        </td>
        <td style={{ verticalAlign: 'middle', }}>{affaire.Statut}</td>
        <td style={{ verticalAlign: 'middle', textAlign:'center' }}>
          <i className="fa fa-search " data-toggle="tooltip" data-trigger="hover" data-placement="left" data-html="true" 
            title={affaire.Comment.replaceAll('\n', '<br/>')}>
          </i>
        </td>
      </tr>);
  }

  generateLineOffre(offre) {

    return (<tr key={offre.CodeoffreSource}>
      <td style={{ verticalAlign: 'middle', textAlign:'center' }}><a href={offre.UrlExterne} target='_blank'>{this.generateLogo(offre.Constructeur)}</a></td>
      <td style={{ verticalAlign: 'middle', }}>{moment(offre.Date).utc().format('DD/MM/YYYY HH:mm')}</td>
      <td style={{ verticalAlign: 'middle', }}>
        <a className='mr-2' href={this.state.linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/offers/' + offre.IDClient} target='_blank'>
          <i className="fa fa-external-link " aria-hidden="true"></i>
        </a>
        {offre.FichierPDF ?
          <a className='mr-2' href={'https://pdf.e-seller.selectup.com/pdf/'+  this.state.urls[0].NomGP +'/'+ offre.FichierPDF} target='_blank'>
            <i className="fa fa-file-pdf-o " aria-hidden="true"></i>
          </a>
        : null}
        {offre.IDOffres}
      </td>
      <td style={{ verticalAlign: 'middle', }}>{offre.Site}</td>
      <td style={{ verticalAlign: 'middle', }}>{offre.Origine}</td>
      <td style={{ verticalAlign: 'middle', }}>
        <a className='mr-2' href={this.state.linkEsellerLead + '/prospect/' + offre.IDClient} target='_blank'>
          <i className="fa fa-external-link " aria-hidden="true"></i>
        </a>
        <a className='mr-2' href={this.state.linkEsellerLead.replace('lead.e-seller.selectup.com', 'crm.edatavoice.com') + '/customers/' + offre.IDClient} target='_blank'>
          <i className="fa fa-external-link " aria-hidden="true"></i>
        </a>
        {offre.Client_name} {offre.Client_phone} {offre.Client_email}
      </td>
      <td style={{ verticalAlign: 'middle', }}>{offre.Statut}</td>
      <td style={{ verticalAlign: 'middle', textAlign:'center' }}>
        <i className="fa fa-search " data-toggle="tooltip" data-trigger="hover" data-placement="left" data-html="true" 
          title={offre.Comment.replaceAll('\n', '<br/>')}>
        </i>
      </td>
    </tr>);
  }

  addMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).add(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).add(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  subMonth() {
    if (this.state.fetchInProgress) {
      return;
    }

    this.setState({
      start_date: moment(this.state.start_date).subtract(1, 'month').startOf('month'),
      end_date: moment(this.state.start_date).subtract(1, 'month').endOf('month'),
    }, () => {
      this.fetchLeads()
    });
  }

  getUrlOngletConstructeur(codeAffaire, commentaire) {

    let url = ""
    const splitComment = commentaire.split('\n')

    splitComment.forEach((part) => {
      if (part.includes("http")) {
        url = part
      }
    })

    if (codeAffaire && !codeAffaire.startsWith("HMF")) {
      if (codeAffaire.includes("|OP-")) {
        const items = codeAffaire.split("|OP-")
        url = `https://www.customer360psa.com/s/opportunity/${items[1]}`
      }
    }

    return url

  }

  render() {


    if (this.state.fetchInProgress) {
      return null;
    }


    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>Robot Wally</h2>

            <br />

            <ul className="nav nav-tabs" id="myTab">
              <li className="nav-item">
                <a className="nav-link active" id="logs-tab" data-toggle="tab" href="#logs" role="tab" aria-controls="logs" aria-selected="true">Leads</a>
              </li>
              <li className="nav-item">
                <a className="nav-link" id="configuration-tab" data-toggle="tab" href="#configuration" role="tab" aria-controls="configuration" aria-selected="true">Offres</a>
              </li>
            </ul>


            <div className="tab-content" id="myTabContent" style={{ paddingTop: '15px' }}>
              <div className="tab-pane fade show active" id="logs" role="tabpanel" aria-labelledby="logs-tab">
                <div>
                  <br />

                  <div className='row' style={{ marginBottom: '15px', }}>
                    <div className="col-md-4 text-right">
                        <a onClick={this.subMonth.bind(this)}>
                          <i className="fa fa-chevron-left"></i>
                        </a>
                    </div>
                    <div className="col-md-4 text-center">
                      Du <b>{this.state.start_date.format('DD/MM/YYYY')}</b> au <b>{this.state.end_date.format('DD/MM/YYYY')}</b>
                    </div>
                    <div className="col-md-4 text-left">
                      <a onClick={this.addMonth.bind(this)}>
                        <i className="fa fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>

                  <table className="table table-bordered table-sm" style={{'fontSize': '11px'}}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Date début affaire</th>
                        <th>Site</th>
                        <th>Origine</th>
                        <th>Prospect</th>
                        <th>Statut</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.affaires && this.state.affaires.length && this.state.affaires.map((affaire) => {

                        var content = []
                        content.push(this.generateLine(affaire));
                        return content;

                      })}
                    </tbody>
                  </table>
                </div>
              </div>
              
              <div className="tab-pane fade " id="configuration" role="tabpanel" aria-labelledby="configuration-tab">
                <div>
                  <br />

                  <div className='row' style={{ marginBottom: '15px', }}>
                    <div className="col-md-4 text-right">
                        <a onClick={this.subMonth.bind(this)}>
                          <i className="fa fa-chevron-left"></i>
                        </a>
                    </div>
                    <div className="col-md-4 text-center">
                      Du <b>{this.state.start_date.format('DD/MM/YYYY')}</b> au <b>{this.state.end_date.format('DD/MM/YYYY')}</b>
                    </div>
                    <div className="col-md-4 text-left">
                      <a onClick={this.addMonth.bind(this)}>
                        <i className="fa fa-chevron-right"></i>
                      </a>
                    </div>
                  </div>

                  <table className="table table-bordered table-sm" style={{'fontSize': '11px'}}>
                    <thead>
                      <tr>
                        <th></th>
                        <th>Date</th>
                        <th>IDOffres</th>
                        <th>Site</th>
                        <th>Origine</th>
                        <th>Prospect</th>
                        <th>Statut</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody>
                      {this.state.offres && this.state.offres.length && this.state.offres.map((offre) => {

                        var content = []
                        content.push(this.generateLineOffre(offre));
                        return content;

                      })}
                    </tbody>
                  </table>
                </div>
              </div>
            </div>


          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);
