import React, { Component } from 'react'
import { withRouter } from 'react-router-dom'

class Index extends Component {

  constructor(props) {
    super(props);

    this.state = {
      config: null,
      updatedConfig: false,
      fetchInProgressConfig: false,
    }
  }

  componentWillMount() {
    this.fetchConfig()
  }

  fetchConfig() {
    fetch(localStorage.backoffice_api + '/api/interfaces/dbs/config', {
      method: 'GET',
      headers: {
        authorization: localStorage.JWT,
      }
    })
      .then((response) => {
        if (!response.ok) {
          throw Error(response.statusText);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          config: json
        });
      })
      .catch((error) => {
        console.log(error)
      });
  }

  onChangeConfig(event) {
    var type = event.target.type
    var name = event.target.name
    var value = event.target.value

    var config = this.state.config

    config[name] = value

    this.setState({
      updatedConfig: true,
      config: config,
    })
  }

  handleSubmitConfig(event) {
    event.preventDefault();

    this.setState({
      fetchInProgressConfig: true,
    })
    console.log(this.state.config)
    fetch(localStorage.backoffice_api + '/api/interfaces/dbs/config', {
      method: 'PUT',
      headers: {
        authorization: localStorage.JWT,
        "Content-Type": "application/json",
      },
      body: JSON.stringify(this.state.config)
    })
      .then((response) => {
        if (!response.ok) {
          response.json().then((value) => {
            alert(value.message)
          });
          this.setState({
            fetchInProgressConfig: false,
          })
          throw Error(response);
        }
        return response.json();
      })
      .then((json) => {
        this.setState({
          config: null,
          updatedConfig: false,
          fetchInProgressConfig: false,
        }, () => {
          this.fetchConfig();
        })
      })
      .catch((error) => {
        console.log(error)
      });
  }

  render() {

    if (!this.state.config)
      return null;

    return (
      <div className='container-fluid'>
        <div className='row'>
          <div className='col'>
            <h2>API DBS</h2>
            <br />
            <form onSubmit={this.handleSubmitConfig.bind(this)}>
              <div className="row">
                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="GRP_DBS_ClientId" className="col-sm-3 col-form-label">ClientId</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="GRP_DBS_ClientId" name="GRP_DBS_ClientId" value={this.state.config.GRP_DBS_ClientId} onChange={this.onChangeConfig.bind(this)} />
                    </div>
                  </div>
                </div>
                <div className="col">
                  <div className="form-group row">
                    <label htmlFor="GRP_DBS_ClientSecret" className="col-sm-3 col-form-label">ClientSecret</label>
                    <div className="col-sm-6">
                      <input type="text" className="form-control form-control-sm" id="GRP_DBS_ClientSecret" name="GRP_DBS_ClientSecret" value={this.state.config.GRP_DBS_ClientSecret} onChange={this.onChangeConfig.bind(this)} />
                    </div>
                  </div>
                </div>
              </div>
              {this.state.updatedConfig ?
                <div className="row mb-2" style={{ marginTop: '15px' }}>
                  <div className="col text-center">
                    <button disabled={this.state.fetchInProgressConfig} type="submit" className="btn btn-primary">
                      {this.state.fetchInProgressConfig ?
                        <span><i className='fa fa-spinner fa-spin fa-fw'></i>&nbsp;</span>
                        : null}
                      Enregistrer
                    </button>
                  </div>
                </div>
                : null}
            </form>
          </div>
        </div>
      </div>
    );
  }
}

export default withRouter(Index);